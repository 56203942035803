// jQuery(($) => {
$(($) => {
    // "use strict";
    $('#visibility').on('change', function (e) {
        e.preventDefault()
        var ref = $(this)

        if (ref.val() == "" || ref.val() == 'PUBLIC') {
            $('#allows_edit_DIV').hide()
        } else {
            $('#allows_edit_DIV').slideDown()
            $('#allows_edit').val('0')
        }
    });


    if (window.location.pathname == '/forms/create' || window.location.pathname == '/forms/' + window.location.pathname.split('/')[2] + '/edit') {
        console.log('single form');
        console.log(window.location.href);
        // create the form editor
        var fbEditor = $(document.getElementById('fb-editor'))
        var formBuilder
        var fbOptions = {
            dataType: 'json',
            formData: window._form_builder_content ? window._form_builder_content : '',
            controlOrder: [
                'header',
                'paragraph',
                'text',
                'textarea',
                'select',
                'number',
                'date',
                'autocomplete',
                'file',
            ],
            disableFields: [
                'button',
            ],
            disabledAttrs: [
                'access',
            ],
            typeUserDisabledAttrs: {
                'file': [
                    'multiple',
                    'subtype',
                ],
                'checkbox-group': [
                    'other',
                ],
            },
            i18n: {
                location: '/assets/lang/'
            },
            showActionButtons: false, // show the actions buttons at the bottom
            disabledActionButtons: ['data'], // get rid of the 'getData' button
            sortableControls: false, // allow users to re-order the controls to their liking
            editOnAdd: false,
            fieldRemoveWarn: false,
            roles: window.FormBuilder.form_roles || {},
            notify: {
                error: function (message) {
                    return swal('Error', message, 'error')
                },
                success: function (message) {
                    return swal('Success', message, 'success')
                },
                warning: function (message) {
                    return swal('Warning', message, 'warning');
                }
            },
            onSave: function () {
                // var formData = formBuilder.formData
                // console.log(formData)
            },
        }


        formBuilder = fbEditor.formBuilder(fbOptions)

        var fbClearBtn = $('.fb-clear-btn')
        var fbShowDataBtn = $('.fb-showdata-btn')
        var fbSaveBtn = $('.fb-save-btn')

        // setup the buttons to respond to save and clear
        fbClearBtn.on('click',function (e) {
            e.preventDefault()

            // console.log(formBuilder);

            if (!formBuilder.actions.getData().length) return

            sConfirm("Are you sure you want to clear all fields from the form?", function () {
                formBuilder.actions.clearFields()
            })
        });

        fbShowDataBtn.on('click',function (e) {
            e.preventDefault()
            formBuilder.actions.showData()
        });

        fbSaveBtn.on('click',function (e) {
            e.preventDefault()

            var form = $('#createFormForm')

            // make sure the form is valid
            if (!form.parsley().validate()) return

            // make sure the form builder is not empty
            if (!formBuilder.actions.getData().length) {
                swal({
                    title: "Error",
                    text: "The form builder cannot be empty",
                    icon: 'error',
                })
                return
            }

            // ask for confirmation
            sConfirm("Save this form definition?", function () {
                fbSaveBtn.attr('disabled', 'disabled');
                fbClearBtn.attr('disabled', 'disabled');

                var formBuilderJSONData = formBuilder.actions.getData('json')

                var postData = {
                    name: $('#name').val(),
                    theme: $('#theme').val(),
                    visibility: $('#visibility').val(),
                    pages: $('#pages').val(),
                    allows_edit: $('#allows_edit').val(),
                    form_builder_json: formBuilderJSONData,
                }
                // console.log(postData);

                var method = form.data('formMethod') ? 'PUT' : 'POST'
                jQuery.ajax({
                        url: form.attr('action'),
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                        processData: true,
                        data: postData,
                        method: method,
                        cache: false,
                    })
                    .then(function (response) {
                        fbSaveBtn.removeAttr('disabled')
                        fbClearBtn.removeAttr('disabled')


                        if (response.success) {
                            // the form has been created
                            // send the user to the form index page
                            swal({
                                title: "Form Saved!",
                                text: response.details || '',
                                icon: 'success',
                            })

                            setTimeout(function () {
                                window.location = response.dest
                            }, 1500);
                        } else {
                            swal({
                                title: "Error",
                                text: response.details || 'Error',
                                icon: 'error',
                            })
                        }
                    }, function (error) {
                        handleAjaxError(error)

                        fbSaveBtn.removeAttr('disabled')
                        fbClearBtn.removeAttr('disabled')
                    })
            })
        })

        // show the clear and save buttons
        $('#fb-editor-footer').slideDown();
    } else if (window.location.pathname == '/multitab' ) {
        // console.log('multi-tab');

        var fbEditor = $(document.querySelectorAll('.fb-editor'));

        var $fbPages = $(document.getElementById("form-builder-pages"));
        var addPageTab = document.getElementById("add-page-tab");
        var fbInstances = [];

        var fbOptions = {
            dataType: 'json',
            // formData: window._form_builder_content ? window._form_builder_content : '',
            controlOrder: [
                'header',
                'paragraph',
                'text',
                'textarea',
                'select',
                'number',
                'date',
                'autocomplete',
                'file',
            ],
            disableFields: [
                'button',
            ],
            disabledAttrs: [
                'access',
            ],
            typeUserDisabledAttrs: {
                'file': [
                    'multiple',
                    'subtype',
                ],
                'checkbox-group': [
                    'other',
                ],
            },
            showActionButtons: false, // show the actions buttons at the bottom
            disabledActionButtons: ['data'], // get rid of the 'getData' button
            sortableControls: false, // allow users to re-order the controls to their liking
            editOnAdd: false,
            fieldRemoveWarn: false,
            // roles: window.FormBuilder.form_roles || {},
            notify: {
                error: function (message) {
                    return swal('Error', message, 'error')
                },
                success: function (message) {
                    return swal('Success', message, 'success')
                },
                warning: function (message) {
                    return swal('Warning', message, 'warning');
                }
            },
            onSave: function () {
                // var formData = formBuilder.formData
                // console.log(formData)
            },
        }


        $fbPages.tabs({
            beforeActivate: function (event, ui) {
                if (ui.newPanel.selector === "#new-page") {
                    return false;
                }
            }
        });

        addPageTab.addEventListener(
            "click",
            (click) => {
                click.preventDefault();
                const tabCount = document.getElementById("tabs").children.length;
                const tabId = "page-" + tabCount.toString();
                const $newPageTemplate = document.getElementById("new-page");
                const $newTabTemplate = document.getElementById("add-page-tab");
                const $newPage = $newPageTemplate.cloneNode(true);
                $newPage.setAttribute("id", tabId);
                $newPage.classList.add("fb-editor");

                const $newTab = $newTabTemplate.cloneNode(true);

                $newTab.removeAttribute("id");
                const $tabLink = $newTab.querySelector("a");
                $tabLink.setAttribute("href", "#" + tabId);
                $tabLink.innerText = "Page " + tabCount;

                $newPageTemplate.parentElement.insertBefore($newPage, $newPageTemplate);
                $newTabTemplate.parentElement.insertBefore($newTab, $newTabTemplate);
                $fbPages.tabs("refresh");
                $fbPages.tabs("option", "active", tabCount - 1);

                fbInstances.push($($newPage).formBuilder(fbOptions));
            }, false);


        fbInstances.push(fbEditor.formBuilder(fbOptions));

        var fbSaveBtn = $('#save-all');

        var fbClearBtn = $('.fb-clear-btn')

        $(document.getElementById("save-all")).on('click',function (e) {
            e.preventDefault();

            const allData = fbInstances.map((fb) => {
                return fb.actions.getData('json');
            });


            sConfirm("Save this form definition?", function () {
                fbSaveBtn.attr('disabled', 'disabled');
                fbClearBtn.attr('disabled', 'disabled');

                var forma = $('#form-builder-pages');

                var postData = {
                    name: $('#name').val(),
                    theme: $('#theme').val(),
                    multi_tab: $('#multiTabCheck').val(),
                    visibility: $('#visibility').val(),
                    allows_edit: $('#allows_edit').val(),
                    form_builder_json: JSON.stringify(allData),
                }

                var method = forma.data('formMethod') ? 'PUT' : 'POST'
                jQuery.ajax({
                        url: forma.attr('action'),
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                        processData: true,
                        data: postData,
                        method: method,
                        cache: false,
                    })
                    .then(function (response) {
                        fbSaveBtn.removeAttr('disabled')
                        fbClearBtn.removeAttr('disabled')

                        if (response.success) {
                            // the form has been created
                            // send the user to the form index page
                            swal({
                                title: "Form Saved!",
                                text: response.details || '',
                                icon: 'success',
                            })

                            setTimeout(function () {
                                window.location = response.dest
                            }, 1500);
                        } else {
                            swal({
                                title: "Error",
                                text: response.details || 'Error',
                                icon: 'error',
                            })
                        }
                    }, function (error) {
                        handleAjaxError(error)

                        fbSaveBtn.removeAttr('disabled')
                        fbClearBtn.removeAttr('disabled')
                    })
            })
        });

    } else if (window.location.pathname == '/multitab/' + window.location.pathname.split('/')[2] + '/edit') {
        console.log('this is multitab');
        // var fbEditor = $(document.querySelectorAll('.fb-editor'));

        //as was
        // let FieldFromForm = _form_builder_content;

        // let FieldFromForm = JSON.parse(_form_builder_content);
        // console.log(FieldFromForm);

        // let container = $('.doveSono');
        // let tabsonUI = $('#tabs');
        // console.log(tabsonUI);
        // // console.log(container);

        // let returnSingleThing = FieldFromForm.map((element, index) => {
        //     // tabs
        //     // let

        //     // console.log(element);
        // })

        // FieldFromForm.foreEach((what) => {
        //     console.log(what);
        // })





        // console.log(FieldFromForm);
        // console.log(FieldFromForm);
        // FieldFromForm.foreaEach((oneArr) => {
        //     console.log(oneArr);
        // })

    }

})
