$(() => {
    // console.log($('#roleSelect').select2());
    // console.log(select2());

    $('#roleSelect').select2({
        width: '100%',
        placeholder: "Select an Option",
        allowClear: true,
        minimumResultsForSearch: Infinity
    });


    $(document).on('click', '.editUser', (event) => {
            $('.rowPrecedenti').empty();
            let aPri = document.querySelector('.containerEl');

            let idApri = event.target.getAttribute('id');

            const userFound = users.filter(v => {
                return v.id == idApri;
            });

            let userRolesInit = userFound.map((user) => {
                return user.roles.map((role) => {
                    return role;
                })
            });

            let rowToInsert = $('.rowPrecedenti');
            if (userRolesInit[0].length != 0) {
                userRolesInit[0].forEach((single, index) => {
                rowToInsert
                .append(
                    `
                    <div class="col">
                        <span class="badge w-100 rounded-pill text-white bg-dark mb-2" data-name="user-${userFound[0].id}-${single.id}">
                            ${single.name}
                            <i class="fa fa-times deleteRole ml-2" aria-hidden="true" id="user-${userFound[0].id}-${single.id}"></i>
                        </span>
                    </div>
                    `
                    );

                $(aPri)
                .addClass('animate__animated animate__slideInLeft')
                .removeClass('d-none');
            });

            $('.deleteRole').on('click', (d) => {
                let indentificaId = d.target.getAttribute('id');
                let result = indentificaId.slice(7, 8);
                let idOfUser = userFound.map(user => user.id);
                let postData = {
                    role: result,
                }

                $.ajax({
                        url: route('role.destroy', idOfUser),
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr(
                                'content')
                        },
                        processData: true,
                        data: postData,
                        method: 'DELETE',
                        cache: false,
                    })
                    .then(function(response) {
                        $(d.target).parent().parent().remove();
                    })
            })
        }

        let buttonToSubmit = $('.buttoniSubmit');

        buttonToSubmit
        .html(`
            <div class="col-12 mt-3">
                <button type="button" class="btn btn-sm loginBtn fb-clear-btn buttonSubmit">Assign Role </button>
            </div>
        `);

        $(document).on('change', '#myselect', function(event) {
            let valueOfChange = $(this).val();

            let valueChangeName = $(this).find(':selected').data('role');

            let buttonPerAjax = $('.buttonSubmit');
            let ruoli = $('.ruoLi');


            $(buttonPerAjax).click((mar) => {
                let idOfUser = userFound.map(user => user.id);

                let postData = {
                    role: valueOfChange,
                }

                $.ajax({
                        url: route('user.role', idOfUser),
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr(
                                'content')
                        },
                        processData: true,
                        data: postData,
                        method: 'POST',
                        cache: false,
                })
                .then(function(response) {

                    ruoli.append(
                        `
                        <small class="btnBlackApp p-1 align-self-center d-inline-block mt-1">
                        ${valueChangeName}
                        </small>
                        `
                    );
                })
            })
        })
    });
});
