$(() => {
    let theIndentifier = route().params;

    if (route().current('form.render', theIndentifier.identifier) === true) {
        const routeRender = route('api.render', theIndentifier);

        $('.selection').select2({
            width: '100%',
            placeholder: "Scegli una opzione",
            allowClear: true,
            minimumResultsForSearch: Infinity
        });


        const axiosTest = async () => {
            const response = await axios.get(routeRender);
            return response.data
        }

        const dataExtract = axiosTest();

        // console.log(dataExtract);

        dataExtract.then((result) => {

            const multitab = result.multiTabs;

            const objects = result.singleTab;

            if (objects != null && multitab == null) {

                require('./withFixed');

            } else if (objects == null && multitab != null) {
                require('./withTabs');
            }
        })
    }

})
