$(() => {
    let theIndentifier = route().params;
    const routeRender = route('api.render', theIndentifier);

    const axiosTest = async () => {
        const response = await axios.get(routeRender);
        return response.data
    }
    const dataExtract = axiosTest();


    // let current_page = 1;

    dataExtract.then((result) => {


        var rows = result.pages;

        let theme = result.theme;

        var sepArr = [];
        for (let o = 0; o < objectsMulti.length; o++) {
            const lists = objectsMulti[o].length;
            sepArr.push(lists);
        }

        let elementNameInputs = objectsMulti.map((object, index) => {
            return object.map(
                (subobject) => {
                    return subobject.name;
                }
            );
        })


        const list_element = document.getElementById('list');

        var prevBtn = document.querySelector('#prevBtn');
        var prevBtnMobile = document.querySelector('#prevBtnMob')

        var nextBtn = document.querySelector('#nextBtn');
        var nextBtnMobile = document.querySelector('#nextBtnMob');
        var submitBtn = document.querySelector('#forSubmit');

        var hiddenfields = document.querySelector('#hiddenfields');

        let xInput = document.querySelector(".textOn");

        var arrayElements = [];

        var arrayElements2 = [];
        var arrayElements3 = [];

        for (let i = 0; i < objectsMulti.length; i++) {
            let item = objectsMulti[i];
            for (let j = 0; j < item.length; j++) {
                const element = item[j];
                // console.log(element);
                let itemText1 = '';
                let itemFile = '';
                let itemTextarea = '';
                let itemParagraph = '';
                let itemNumber = '';

                let itemSelectAround = '';
                let itemDate = '';
                let itemCheckBox = '';
                let itemRadioGroup = '';

                let item_element = document.createElement('div');
                item_element.classList.add('item', 'pl-2');


                if (element.type === 'paragraph') {
                    itemParagraph =
                        `<div class="d-none"><p id="somet" class="${item.className} text-center">${item.label}</p></div>`;
                }
                if (element.type === 'file') {
                    let description;
                    if (element.description) {
                        description = `*${item.description}`;
                    } else {
                        description = '';
                    }
                    itemFile =
                        `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
                        <div class="fb-file form-group row fileHandle py-1 my-5 field-${element.name}" style="padding-left: 1rem;">
                            <label for="${element.name}" class="fb-file-label w-50 col-8 sectionOform">${element.label}<span class="spanText ml-2 font-italic">${description}</span></label>
                            <input type="${element.type}" class="${element.className} bordeRs d-none w-50 border-0" name="${element.name}" id="${element.name}">
                        </div>`;
                }
                if (element.type === 'text') {
                    let description;
                    if (element.description) {
                        description = `*${element.description}`;
                    } else {
                        description = '';
                    }
                    itemText1 =
                        `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
            <div class="fb-text form-group row py-5 field-${element.name}" style="padding-left: 1rem;">
                    <label for="${element.name}" class="fb-text-label w-100 col-8 sectionOform">${element.label}<span class="spanText ml-2">${description}</span></label>
                    <input type="${element.type}" class="${element.className} bordeRs w-50 border-0 textOn" name="${element.name}" id="${element.name}">
            </div>`;
                }
                if (element.type === 'textarea') {
                    let description;
                    if (element.description) {
                        description = `*${element.description}`;
                    } else {
                        description = '';
                    }
                    itemTextarea =
                        `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
            <div class="fb-textarea pt-1 pb-1 row form-group field-${element.name}" style="padding-left: 1rem;">
                <label for="${element.name}" class="fb-text-label w-100 sectionOform">${element.label}<span class="spanText ml-2 font-italic">${description}</span></label>
                <textarea type="${element.type}" class="${element.className} w-50 ml-0 rounded-0 textAreaInp" name="${element.name}" id="${element.name}"> </textarea>
            </div>`;
                }
                if (element.type === 'number') {
                    let description;
                    if (element.description) {
                        description = `*${element.description}`;
                    } else {
                        description = '';
                    }
                    itemNumber =
                        `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
            <div class="fb-number py-5 form-group field-${element.name}" style="padding-left: .5rem;">
                <label for="${element.name}" class="fb-number-label w-100 sectionOform">${element.label}<span class="spanText ml-2 font-italic">${description}</span></label>
                <input type="${element.type}" class="${element.className} w-25 bordeRs numberInput border-0" name="${element.name}" id="${element.name}" />
            </div>`;
                }
                if (element.type === 'date') {
                    let description;
                    if (element.description) {
                        description = `*${element.description}`;
                    } else {
                        description = '';
                    }
                    itemDate =
                        `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
            <div class="fb-date my-5 py-1 form-group field-${element.name}" style="padding-left: .5rem;">
                <label for="${element.name}" class="fb-date-label w-100 sectionOform">${element.label}<span class="spanText ml-2 font-italic">${description}</span></label>
                <input type="${element.type}" class="${element.className} w-25 borderS dtInput border-0" name="${element.name}" id="${element.name}" />
            </div>`;
                }

                if (element.type === 'checkbox-group') {
                    let description;
                    // console.log(element.name);
                    // console.log('keshtu');
                    if (element.description) {
                        description = `*${element.description}`;
                    } else {
                        description = '';
                    }
                    itemSelectCheck = '';
                    number = 0;
                    let onlyChecks = element.values;

                    for (let i = 0; i < onlyChecks.length; i++) {
                        const elementi = onlyChecks[i];
                        number++;
                        itemSelectCheck +=
                            `<div name="${element.name}" id="${element.name}" class="checkbox row hoverBitem d-flex py-2">
                        <input class="option-input checkbox" name="${element.name}[]" id="${element.name}-${number}" value="${elementi.value}" type="checkbox">
                    <label class="pl-0 formData" for="${element.name}-${number}">${elementi.label}</label>
                </div>`;
                    }

                    itemCheckBox =
                        `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
            <div class="fb-checkbox-group row pt-1 pb-1 form-group field-${element.name}">
                <label for="${element.name}" class="fb-checkbox-group-label sectionOform col-10">${element.label} <span class="spanText ml-2 font-italic">${description}</span></label>
                <div class="${element.type} justify-content-center col-12 mx-auto" style="width: 95%; padding-left: 2rem;"> ${itemSelectCheck} </div>
            </div>`;

                }

                if (element.type === 'radio-group') {
                    let description;

                    if (element.description) {
                        description = `*${element.description}`;
                    } else {
                        description = '';
                    }

                    itemSelectRadio = '';

                    number = 0;

                    let onlyRadios = element.values;

                    for (let i = 0; i < onlyRadios.length; i++) {
                        const elementi = onlyRadios[i];
                        number++;
                        itemSelectRadio +=
                            `<div class="radio row hoverBitem d-flex py-2">
                    <input class="option-input inputChecked radio" name="${element.name}" id="${element.name}-${number}" value="${elementi.value}" type="radio">
                    <label class="pl-0 formData mb-0" for="${element.name}-${number}">${elementi.label}</label>
                </div>`;
                    }
                    itemRadioGroup =
                        `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
            <div class="fb-radio-group row pt-1 pb-1 form-group field-${element.name}">
                <label for="${element.name}" class="fb-radio-group-label col-10 sectionOform">${element.label}<span class="spanText ml-2 font-italic">${description}</span></label>
                <div class="${element.type} justify-content-center col-12 mx-auto" style="width: 95%; padding-left: 2rem;"> ${itemSelectRadio} </div>
            </div>`;
                }

                if (element.type === 'select') {
                    let description;

                    // console.log(element.name);
                    if (element.description) {
                        description = `*${element.description}`;
                    } else {
                        description = '';
                    }
                    itemSelectOpt = '';
                    number = 0;
                    let onlyValues = element.values;
                    for (let i = 0; i < onlyValues.length; i++) {
                        const elementi = onlyValues[i];
                        number++;
                        itemSelectOpt +=
                            `<option class="itemSelection" value="${elementi.value}" id="select-${element.name}-${number}">${elementi.label}</option>`;
                    }

                    itemSelectAround =
                        `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
            <div class="fb-select pt-1 pb-1 form-group field-${element.name}">
                <label for="${element.name}" class="fb-select-label sectionOform w-100 text-left">${element.label}<span class="spanText ml-2 font-italic">${description}</span>
                </label>
                <select type="${element.type}" class="form-control d-block d-lg-none selectpicker itemSelect justify-content-center ml-0" data-size="5" data-mobile="true" data-title="Select" data-style="backGroundOf" data-live-search="true" name="${element.name}" id="${element.name}">
                    ${itemSelectOpt}
                </select>
                <select type="${element.type}" class="form-control d-none d-lg-block selectpicker itemSelect justify-content-center ml-0" data-size="7" data-title="Select" data-style="backGroundOf" data-live-search="true" name="${element.name}" id="${element.name}">
                    ${itemSelectOpt}
                </select>
            </div>`;
                }

                item_element.innerHTML += itemText1 + itemTextarea + itemParagraph + itemNumber + itemSelectAround +
                    itemDate + itemCheckBox + itemRadioGroup + itemFile;

                arrayElements.push(item_element);

            }
        }

        function percentage(partialValue, totalValue) {
            return (100 * partialValue) / totalValue;
        }

        const chunk = (cards, chunksizes) => {
            const chunkArray = [];
            let cc = 0,
                i = 0;
            while (i < cards.length) {
                const csize = chunksizes[cc];
                chunkArray.push(cards.slice(i, i + csize));
                cc = (cc + 1) % chunksizes.length;
                i += csize;
            }
            return chunkArray;
        }


        let steps = chunk(arrayElements, sepArr);

        steps.forEach((elementi, key) => {
            let newTab = document.createElement('div');
            newTab.setAttribute('class', 'tab');
            elementi.forEach(el => {
                newTab.appendChild(el);
            });
            list_element.appendChild(newTab);
        });

        let totalItem = document.querySelectorAll('.tab');

        let lengthOfTabs = totalItem.length;
        let singleStep = 1;

        let percentageCalc = percentage(singleStep, lengthOfTabs);

        let statementArray = [];

        var currentTab = 0;

        showTab(currentTab);

        let text;
        let file;
        let select;
        let radiogroup;
        let checkboxgroup;
        let date;
        let numberInput;
        let textarea;

        function showTab(n) {
            var x = document.getElementsByClassName("tab");
            x[n].style.display = "block";

            if (n == 0) {
                document.getElementById("prevBtn").style.display = "none";
            } else if (n > 0) {
                document.getElementById("prevBtn").style.display = "flex";
            }

            let checkValue = elementNameInputs[n].map((arr, index) => {

                let elementi = document.getElementsByName(`${arr}`);

                $(elementi).change((event) => {

                    let selector = event.target.attributes.id.value;

                    if (selector.includes("text") === true) {
                        text = event.target.value;
                    } else if (selector.includes("file") === true) {
                        file = event.target.value;
                    } else if (selector.includes("select") === true) {
                        select = event.target.value;
                    } else if (selector.includes("radio-group") === true) {
                        radiogroup = event.target.value;
                    } else if (selector.includes("checkbox-group") === true) {
                        checkboxgroup = event.target.value;
                    } else if (selector.includes("date") === true) {
                        date = event.target.value;
                    } else if (selector.includes("number") === true) {
                        numberInput = event.target.value;
                    } else if (selector.includes("textarea") === true) {
                        textarea = event.target.value;
                    }
                })
            });

            if (n == (x.length - 1)) {
                document.getElementById("nextBtn").innerHTML = "Submit";
            } else {
                document.getElementById("nextBtn").innerHTML = "Succ.";
            }
        }

        let count = 0;

        // console.log(currentTab);


        $(nextBtn).click((event) => {
            event.preventDefault();
            nextPrev(1);

            count++;

            let response = [
                text,
                file,
                select,
                radiogroup,
                checkboxgroup,
                date,
                numberInput,
                textarea
            ];

            const results = response.filter(element => {
                return element !== undefined;
            });

            let savedData = [];

            var postData = {
                // content: JSON.stringify(results),
                content: results,
            }


            if (currentTab == 1 && count == 1) {
                $.ajax({
                        url: "{{ route('api.form', $identifier) }}",
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                        processData: true,
                        data: postData,
                        method: 'POST',
                        cache: false,
                    })
                    .then(function (response) {
                        if (response.success) {
                            savedData.push(results);
                        } else {
                            console.log(response.details || 'Error');
                        }
                    })
            } else if (currentTab > 1 && count > 1) {
                $.ajax({
                        url: "{{ route('api.formupdate', [$identifier, $formpartial->id]) }}",
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                        processData: true,
                        data: postData,
                        method: 'PUT',
                        cache: false,
                    })
                    .then(function (response) {
                        if (response.success) {
                            savedData.push(results);
                            postData = {
                                content: savedData,
                            }
                        } else {
                            console.log(response.details || 'Error');
                        }
                    })
            }
        });

        $(prevBtn).click((event) => {
            event.preventDefault();
            nextPrev(-1);
        });


        function nextPrev(n) {
            var x = document.getElementsByClassName("tab");

            if (n == 0) {
                $('#nextBtn').parent().removeClass('col-6').addClass('col-12');
            } else {
                $('#nextBtn').parent().removeClass('col-12').addClass('col-6');
            }

            if (n == 1 && !validateForm()) return false;

            x[currentTab].style.display = "none";

            currentTab = currentTab + n;

            if (currentTab >= x.length - 1) {
                document.getElementById("regForm").submit();
                return false;
            }

            showTab(currentTab);
        }

        function validateForm() {
            var x, y, i, validationErro = true;

            var u = true;
            var ar = true;
            var o = true;
            var d = true;
            var nr = true;
            var valid = true;

            x = document.getElementsByClassName("tab");

            validationErro = x[currentTab].querySelectorAll('.validationErr');


            y = x[currentTab].querySelectorAll(".inputChecked");
            u = x[currentTab].querySelectorAll(".textOn");
            o = x[currentTab].querySelectorAll(".itemSelection");
            // ch = x[currentTab].querySelectorAll(".option-input");
            d = x[currentTab].querySelectorAll(".dtInput");
            ar = x[currentTab].querySelectorAll(".textAreaInp");
            nr = x[currentTab].querySelectorAll(".numberInput");

            const checked = [];

            if (y) {
                for (i = 0; i < y.length; i++) {
                    let context = (y[i].checked) ? true : false;
                    checked.push(context);
                }
            }

            if (u) {
                for (i = 0; i < u.length; i++) {
                    if (u[i].value == "") {
                        valid = false;
                    }
                }
            }

            if (ar) {
                for (i = 0; i < ar.length; i++) {
                    if (ar[i].value == "") {
                        valid = false;
                    }
                }
            }

            const selection = [];
            if (o) {
                for (i = 0; i < o.length; i++) {
                    let context = (o[i].selected) ? true : false;
                    selection.push(context);
                }
            }

            if (d) {
                for (i = 0; i < d.length; i++) {
                    console.log(d[i].value);
                    if (d[i].value == "") {
                        valid = false;
                    }
                }
            }

            if (nr) {
                for (i = 0; i < nr.length; i++) {
                    // console.log(nr[i].value);
                    if (nr[i].value == "") {
                        valid = false;
                    }
                }
            }

            if (selection.length != 0) {
                valid = (!selection.includes(true)) ? false : true;
            }
            if (checked.length != 0) {
                valid = (!checked.includes(true)) ? false : true;
            }

            if (valid) {
                validationErro.forEach(e => {
                    e.style.display = 'none'
                });
            } else {
                validationErro.forEach(e => {
                    e.style.display = 'block'
                });
            }
            statementArray.push(valid);

            return valid;
        }

        function incr() {
            var v1 = document.getElementById('p1').value;
            var statement = statementArray.reverse()[0];
            var statement2 = arrayElements2.reverse()[0];

            console.log(statement);


            var statement3 = arrayElements3.reverse()[0];

            if (statement === true) {

                document.getElementById("p1").value = v1 + percentageCalc;
                let calC = document.getElementById("p1").value = v1 + percentageCalc;
                let passPercent = Math.round(calC);
                var percentSpan = document.getElementById('percentuale').innerText = `${passPercent}%`;
            } else if (statement === false) {

                document.getElementById("p1").value;
            }
        }

        function decr() {
            var v1 = document.getElementById('p1').value;

            document.getElementById("p1").value = v1 - percentageCalc;

            let calC = document.getElementById("p1").value = v1 - percentageCalc;
            let passPercent = Math.round(calC);
            var percentSpan = document.getElementById('percentuale').innerText = `${passPercent}%`;
        }

    })

    console.log(dataExtract);
})
