require('./bootstrap');

// require('./create-multitab');
require('./render-form');
require('./formfront');
require('./create-form');

require('formBuilder/dist/form-builder.min');
require('formBuilder/dist/form-render.min');

require('animate.css');
require('./users');

const animateCSS = (element, animation, prefix = 'animate__') =>
    new Promise((resolve, reject) => {
        const animationName = `${prefix}${animation}`;
        const node = document.querySelector(element);

        node.classList.add(`${prefix}animated`, animationName);

        // When the animation ends, we clean the classes and resolve the Promise
        function handleAnimationEnd(event) {
            event.stopPropagation();
            node.classList.remove(`${prefix}animated`, animationName);
            resolve('Animation ended');
        }

        node.addEventListener('animationend', handleAnimationEnd, {
            once: true
        });
    });

$(() => {

    let elementCol = $('.iconAdd');

    let currentUser = (user) => {
        return (user ? userAuthRole : null)
    }

    let userRole = (role) => {
        return (role ? userAuth : null)
    }

    var user;
    var role;

    if (currentUser(false)) {
        user = null;
        role = null;

    } else if (currentUser(true)) {
        role = currentUser(true);
        user = userRole(true);
    }

    $(window).on('resize', function () {
        if ($(this).width() <= 480) {
            // console.log('screen width');
            // w <= 480)
        } else {
            console.log('desktop');
        }
        console.log($(this).width());
    });

    let textName = $('.textForJS');

    let elementContent = $('.menuToggle');
    elementContent.html('<button class="mx-auto d-flex bg-transparent bord"><i class="fa fa-bars iconToOpen mx-auto d-block mt-5" aria-hidden="true"></i></button>');

    let toOpen = $('.iconToOpen');

    let count = 0;

    $(elementCol).on('click', toOpen, (event) => {
        event.stopPropagation();
        let element = event.target;

        if (count % 2 == 0) {
            count++;
            $(elementCol).removeClass('colcustom').addClass('col-2');

            $(element).css({
                'font-size': '2rem',
                'text-align': 'left'
            });

            animateCSS('.iconToOpen', 'bounce');
            animateCSS('.iconAdd', 'slideInLeft');
            $(element).removeClass('fa-bars').addClass('fa-times');

            $('.expandCol').removeClass('col-9').addClass('col-8');

            $('.menuContent').removeClass('d-none').addClass('d-flex');
            let menuContentToOpen = $('.menuContent');

            let home = route('form.dashboard');
            let users = route('users.dashboard');
            let submissions = route('my-submissions.index');
            let formSingle = route('forms.create');
            let formMulti = route('form.multitab');

            // route('my-submissions.index') }}

            if (role[0] === 'Super-Admin') {
                menuContentToOpen.html(
                    `
                <ul class="w-100" style="list-style: none;">
                <li class="firstLi"> <a class="text-decoration-none textMenu" href="${home}"> Dashboard </a> </li>
                <li class="firstLi text-decoration-none textMenu"> Forme
                <ul class="subList ml-n3" style="list-style-type: none;">
                <li class="submenuEl">
                <i class="fa fa-plus-circle"></i><a class="text-decoration-none textMenuSub" href="${formSingle}">
                Create Form
                </a>
                </li>
                <li class="submenuEl">
                <i class="fa fa-plus-circle"></i><a class="text-decoration-none textMenuSub" href="${formMulti}">
                Create Multi-Tab
                </a>
                </li>
                </ul>
                </li>
                <li class="firstLi"> <a class="text-decoration-none textMenu" href="${submissions}"> Submissions </a> </li>
                <li class="firstLi"><a class="text-decoration-none textMenu" href="${users}"> Users </a> </li>
                </ul>
                `
                );
            } else {
                menuContentToOpen.html(
                    `
                <ul style="list-style: none;">
                <li class="firstLi"> <a class="text-decoration-none textMenu" href="${home}"> Dashboard </a> </li>
                <li class="firstLi text-decoration-none textMenu"> Forme
                <ul class="subList ml-n3" style="list-style-type: none;">
                <li class="submenuEl">
                <i class="fa fa-plus-circle"></i><a class="text-decoration-none textMenuSub" href="${formSingle}">
                Create Form
                </a>
                </li>
                <li class="submenuEl">
                <i class="fa fa-plus-circle"></i><a class="text-decoration-none textMenuSub" href="${formMulti}">
                Create Multi-Tab
                </a>
                </li>
                </ul>
                </li>
                </ul>
                `
                );
            }

            let allLis = document.querySelectorAll('.firstLi');

            allLis.forEach((element, index) => {
                $(element).css('opacity', 0).delay(`${index}000`).animate({
                        opacity: '1'
                    },
                    "fast").addClass('animate__animated animate__slideInUp ').addClass(`animate__delay-${index}s`).css('--animate-duration', '.2s');
            });

            // class="submenuEl"
            let allSubLis = document.querySelectorAll('.submenuEl');

            allSubLis.forEach((subel, index) => {
                // console.log(`${index++}000 + 1`);
                const sum = index + 2;
                const sumDelay = sum - 1;

                $(subel).css('opacity', 0).delay(`${sum}000`).animate({
                        opacity: '1'
                    },
                    "fast").addClass('animate__animated animate__slideInUp ').css({
                    '--animate-duration': '.2s'
                }, {
                    '--animate__delay': '${sumDelay}s'
                });
            })


            let parent = $(element).parent()[0];

            $(parent).toggleClass('d-flex');

        } else {
            count++;
            $(elementCol).removeClass('col-2').addClass('colcustom');
            $(element).css({
                'font-size': '6rem',
                'text-align': 'center'
            });

            $(element).removeClass('fa-times').addClass('fa-bars');
            $('.expandCol').removeClass('col-8').addClass('col-9');

            $('.menuContent').removeClass('d-flex').addClass('d-none');

            let parent = $(element).parent()[0];

            $(parent).toggleClass('d-flex');
        }
    });



    $('.goToMedikey').on('click', function (event) {
        event.preventDefault();
        $('#medLogin').css('display', 'flex');
        $('#formLogin').css('display', 'none');
        $(this).hide();
        $('.goToForm').show();

    });
    $('.goToForm').on('click', function (event) {
        event.preventDefault();
        $('#formLogin').css('display', 'block');
        $('#medLogin').css('display', 'none');
        $(this).hide();
        $('.goToMedikey').show();
    });

    let animationStart = $('.animationToMake');
    animationStart.addClass("animate__animated animate__fadeInUpBig");

    let animatedTextInit = $('.perIndex');
    let animatedThonjez = $('.textThonjez');
    animatedThonjez.delay(1500).animate({
        opacity: .4
    }, 'slow');
    animatedTextInit.delay(2000).animate({
        opacity: .9
    }, 'slow');


    const element = document.querySelector('.overHover');
    const hovered = document.querySelector('.hoverOver');

    $(element).on('hover', function () {
        $(hovered).animate({
            opacity: '1'
        });
        $(hovered).addClass("animate__animated animate__fadeInDown");
    }, function () {
        $(hovered).animate({
            opacity: '0'
        });
        $(hovered).removeClass("animate__animated animate__fadeInDown");
    });

})
