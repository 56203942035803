$(() => {
    let theIndentifier = route().params;
    const routeRender = route('api.render', theIndentifier);

    const axiosTest = async () => {
        const response = await axios.get(routeRender);
        return response.data
    }
    const dataExtract = axiosTest();


    dataExtract.then((result) => {

        let formpartial = result.formpartial;

        var rows = result.pages;

        let theme = result.theme;
        let indentifier = result.identifier;

        const objects = result.singleTab;
        // const multitab = result.multiTabs;

        let elementNameInputs;

        const list_element = document.getElementById('list');

        var prevBtn = document.querySelector('#prevBtn');

        // console.log(prevBtn);

        var prevBtnMobile = document.querySelector('#prevBtnMob');

        var nextBtn = document.querySelector('#nextBtn');
        var nextBtnMobile = document.querySelector('#nextBtnMob');
        // var submitBtn = document.querySelector('#forSubmit');

        // var hiddenfields = document.querySelector('#hiddenfields');

        var arrayElements = [];

        var arrayElements2 = [];


        var prevBtn = document.querySelector('#prevBtn');
        var prevBtnMobile = document.querySelector('#prevBtnMob');

        // console.log(prevBtnMobile);
        // submitBtn.style.backgroundColor = `${theme}`;
        nextBtnMobile.style.backgroundColor = `${theme}`;
        nextBtn.style.backgroundColor = `${theme}`;
        prevBtn.style.backgroundColor = '#dddddd';
        prevBtnMobile.style.backgroundColor = `${theme}`;

        var nextBtn = document.querySelector('#nextBtn');
        var nextBtnMobile = document.querySelector('#nextBtnMob');
        // var submitBtn = document.querySelector('#forSubmit');

        // var hiddenfields = document.querySelector('#hiddenfields');

        // let xInput = document.querySelector(".textOn");

        var arrayElements = [];

        var arrayElements2 = [];


        for (let i = 0; i < objects.length; i++) {
            let item = objects[i];

            let itemText1 = '';
            let itemFile = '';
            let itemTextarea = '';
            let itemParagraph = '';
            let itemNumber = '';

            let itemSelectAround = '';
            let itemDate = '';
            let itemCheckBox = '';
            let itemRadioGroup = '';

            let item_element = document.createElement('div');
            item_element.classList.add('item', 'pl-2');


            if (item.type == 'paragraph') {
                itemParagraph =
                    `<div class="d-none"><p id="somet" class="${item.className} text-center">${item.label}</p></div>`;
            }
            if (item.type == 'file') {
                let description;
                if (item.description) {
                    description = `*${item.description}`;
                } else {
                    description = '';
                }
                itemFile =
                    `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
                        <div class="fb-file form-group row fileHandle py-1 my-5 field-${item.name}" style="padding-left: 1rem;">
                            <label for="${item.name}" class="fb-file-label w-50 col-8 sectionOform">${item.label}<span class="spanText ml-2 font-italic">${description}</span></label>
                            <input type="${item.type}" class="${item.className} validate bordeRs d-none w-50 border-0" required name="${item.name}" id="${item.name}">
                        </div>`;
            }
            if (item.type == 'text') {
                let description;
                if (item.description) {
                    description = `*${item.description}`;
                } else {
                    description = '';
                }
                itemText1 =
                    `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
                        <div class="fb-text form-group row py-5 field-${item.name}" style="padding-left: 1rem;">
                                <label for="${item.name}" class="fb-text-label w-100 col-8 sectionOform">${item.label}<span class="spanText ml-2">${description}</span></label>
                                <input type="${item.type}" required class="${item.className} validate bordeRs w-50 border-0 textOn" name="${item.name}" id="${item.name}">
                        </div>`;
            }
            if (item.type == 'textarea') {
                let description;
                if (item.description) {
                    description = `*${item.description}`;
                } else {
                    description = '';
                }
                itemTextarea =
                    `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
                        <div class="fb-textarea pt-1 pb-1 row form-group field-${item.name}" style="padding-left: 1rem;">
                            <label for="${item.name}" class="fb-text-label w-100 sectionOform">${item.label}<span class="spanText ml-2 font-italic">${description}</span></label>
                            <textarea type="${item.type}" required class="${item.className} validate w-50 ml-0 rounded-0" name="${item.name}" id="${item.name}"> </textarea>
                        </div>`;
            }
            if (item.type == 'number') {
                let description;
                if (item.description) {
                    description = `*${item.description}`;
                } else {
                    description = '';
                }
                itemNumber =
                    `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
                        <div class="fb-number py-5 form-group field-${item.name}" style="padding-left: .5rem;">
                            <label for="${item.name}" class="fb-number-label w-100 sectionOform">${item.label}<span class="spanText ml-2 font-italic">${description}</span></label>
                            <input type="${item.type}" required class="${item.className} validate w-50 bordeRs border-0" name="${item.name}" id="${item.name}" />
                        </div>`;
            }
            if (item.type == 'date') {
                let description;
                if (item.description) {
                    description = `*${item.description}`;
                } else {
                    description = '';
                }
                itemDate =
                        `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
                        <div class="fb-date my-5 py-1 form-group field-${item.name}" style="padding-left: .5rem;">
                            <label for="${item.name}" class="fb-date-label w-100 sectionOform">${item.label}<span class="spanText ml-2 font-italic">${description}</span></label>
                            <input type="${item.type}" required class="${item.className} validate w-50 borderS border-0" name="${item.name}" id="${item.name}" />
                        </div>`;
            }

            if (item.type == 'checkbox-group') {
                let description;
                if (item.description) {
                    description = `*${item.description}`;
                } else {
                    description = '';
                }
                let itemSelectCheck = '';
                let number = 0;
                let onlyChecks = item.values;

                for (let i = 0; i < onlyChecks.length; i++) {
                    const element = onlyChecks[i];
                    number++;
                    itemSelectCheck +=
                        `<div class="row hoverBitem d-flex py-2 validate">
                                <input class="option-input inputChecked checkbox" name="${item.name}[]" id="${item.name}-${number}" value="${element.value}" type="checkbox">
                                <label class="pl-0 formData" for="${item.name}-${number}">${element.label}</label>
                        </div>`;
                }
                itemCheckBox =
                    `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
                        <div class="fb-checkbox-group row pt-1 pb-1 form-group field-${item.name}">
                            <label for="${item.name}" class="fb-checkbox-group-label sectionOform col-10">${item.label} <span class="spanText ml-2 font-italic">${description}</span></label>
                            <div required class="${item.type} justify-content-center col-12 mx-auto" style="width: 95%; padding-left: 2rem;"> ${itemSelectCheck} </div>
                        </div>`;
            }

            if (item.type == 'radio-group') {
                let description;
                if (item.description) {
                    description = `*${item.description}`;
                } else {
                    description = '';
                }
                let itemSelectRadio = '';

                let number = 0;

                let onlyRadios = item.values;
                for (let i = 0; i < onlyRadios.length; i++) {
                    const element = onlyRadios[i];
                    number++;
                    itemSelectRadio +=
                        `<div class="row hoverBitem d-flex py-2 validate">
                                <input class="option-input radio" name="${item.name}" id="${item.name}-${number}" value="${element.value}" type="radio">
                                <label class="pl-0 formData mb-0" for="${item.name}-${number}">${element.label}</label>
                        </div>`;
                }
                itemRadioGroup =
                        `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
                        <div class="fb-radio-group row pt-1 pb-1 form-group field-${item.name}">
                            <label for="${item.name}" class="fb-radio-group-label col-10 sectionOform">${item.label}<span class="spanText ml-2 font-italic">${description}</span></label>
                            <div class="${item.type} justify-content-center col-12 mx-auto" style="width: 95%; padding-left: 2rem;"> ${itemSelectRadio} </div>
                        </div>`;
            }

            if (item.type == 'select') {
                let description;
                if (item.description) {
                    description = `*${item.description}`;
                } else {
                    description = '';
                }
                let itemSelectOpt = '';
                let number = 0;
                let onlyValues = item.values;
                for (let i = 0; i < onlyValues.length; i++) {
                    const element = onlyValues[i];
                    number++;
                    itemSelectOpt +=
                        `<option class="itemSelection" value="${element.value}" id="select-${item.name}-${number}">${element.label}</option>`;
                }

                itemSelectAround =
                    `<p class="validationErr mb-0" style="display: none;"><i class="fa fa-exclamation pr-1"></i>Campo obligatorio</p>
                        <div class="fb-select pt-1 pb-1 form-group field-${item.name}">
                            <label for="${item.name}" class="fb-select-label sectionOform w-100 text-left">${item.label}<span class="spanText ml-2 font-italic">${description}</span>
                            </label>
                            <select type="${item.type}" class="form-control d-block d-lg-none selector itemSelect justify-content-center ml-0" data-size="5" data-mobile="true" data-title="Select" data-style="backGroundOf" data-live-search="true" name="${item.name}" id="${item.name}">
                                ${itemSelectOpt}
                            </select>
                            <select type="${item.type}" class="form-control d-none d-lg-block selector itemSelect justify-content-center ml-0 validate" data-size="7" data-title="Select" data-style="backGroundOf" data-live-search="true" name="${item.name}" id="${item.name}">
                                <option class="itemSelection" value="">Scegli</option>
                                ${itemSelectOpt}
                            </select>
                        </div>`;
            }

            item_element.innerHTML += itemText1 + itemTextarea + itemParagraph + itemNumber + itemSelectAround + itemDate + itemCheckBox + itemRadioGroup + itemFile;


            arrayElements.push(item_element);

        }

        var chunks = function (array, size) {
            var results = [];
            while (array.length) {
                results.push(array.splice(0, size));
            }
            return results;
        };

        function percentage(partialValue, totalValue) {
            return (100 * partialValue) / totalValue;
        }

        let steps = chunks(arrayElements, rows);


        steps.forEach((elementi, key) => {
            let newTab = document.createElement('div');
            newTab.setAttribute('class', 'tab');
            elementi.forEach(el => {
                newTab.appendChild(el);
            });
            list_element.appendChild(newTab);

        });

        let totalItem = document.querySelectorAll('.tab');

        let lengthOfTabs = totalItem.length;
        let singleStep = 1;

        let percentageCalc = percentage(singleStep, lengthOfTabs);

        let statementArray = [];

        var currentTab = 0;
        // $('#prevBtn').hide();

        showTab(currentTab);

        let text;
        let file;
        let select;
        let radiogroup;
        let checkboxgroup;
        let date;
        let numberInput;
        let textarea;

        function showTab(n) {
            var x = document.getElementsByClassName("tab");
            x[n].style.display = "block";

            if (n == 0) {
                document.getElementById("prevBtn").style.display = "none";
            } else if (n > 0) {
                document.getElementById("prevBtn").style.display = "flex";
            }

            let childEl = $(x[n]).children();

            $(childEl).on('change', (event) => {
                let selector = event.target.attributes.id.value;
                if (selector.includes("text") === true) {
                    text = event.target.value;
                } else if (selector.includes("file") === true) {
                    file = event.target.value;
                } else if (selector.includes("select") === true) {
                    select = event.target.value;
                } else if (selector.includes("radio-group") === true) {
                    radiogroup = event.target.value;
                } else if (selector.includes("checkbox-group") === true) {
                    checkboxgroup = event.target.value;
                } else if (selector.includes("date") === true) {
                    date = event.target.value;
                } else if (selector.includes("number") === true) {
                    numberInput = event.target.value;
                } else if (selector.includes("textarea") === true) {
                    textarea = event.target.value;
                }
            })


            if (n == (x.length - 1)) {
                document.getElementById("nextBtn").innerHTML = "Submit";
            } else {
                document.getElementById("nextBtn").innerHTML = "Succ.";
            }
        }

        // let checkForExist =


        function nextPrev(n) {
            var x = document.getElementsByClassName("tab");

            if (n == 0) {
                $('#nextBtn').parent().removeClass('col-6').addClass('col-12');
            } else {
                $('#nextBtn').parent().removeClass('col-12').addClass('col-6');
            }

            if (n == 1 && !validateForm()) return false;

            x[currentTab].style.display = "none";

            currentTab = currentTab + n;


            if (currentTab >= x.length) {
                document.getElementById("regForm").submit();
                return false;
            }

            showTab(currentTab);
        }


        function validateForm() {
            var x, y, i, validationErro = true;

            var valid = true;

            x = document.getElementsByClassName("tab");

            validationErro = x[currentTab].querySelectorAll('.validationErr');

            const validation = {
                set current(name) {
                    //   this.log.push(name);
                    this.log = name;
                },
                log: '',
            }




            validationErro.forEach((e) => {
                const info = $(e).siblings().find('.validate');

                let validate = info.filter(function () {
                    return $(this).val().trim().length == '' || $(this).val() == '';
                });

                validate.map(function () {
                    let pToShow = $(this).parent().siblings();
                    // let checkshow = $(this).parent().parent().siblings();

                    if ($(this).hasClass('hoverBitem')) {
                        // console.log($(this).parent());
                        if ($(this).parent().children().children().is(':checked') === false) {
                            console.log('im here');
                            return $(this).parent().parent().siblings().css('display', 'block');
                        } else {
                            return validate = [];
                        }
                    } else {
                        return $(pToShow).css('display', 'block');
                    }

                });

                if (validate.length > 0) {
                    validation.current = false;
                } else if (validate.length == 0) {
                    validation.current = true;
                }
            });

            return validation.log;
        }

        let count = 0;

        $(nextBtn).on('click', (event) => {

            event.preventDefault();
            nextPrev(1);

            count++;

            let response = [
                text,
                file,
                select,
                radiogroup,
                checkboxgroup,
                date,
                numberInput,
                textarea
            ];

            // $("#regForm").validate;


            const results = response.filter(element => {
                return element !== undefined;
            });


            if (currentTab == 1 && count == 1) {

                const apiPostRoute = route('api.form', indentifier);

                axios.post(apiPostRoute, {
                    content: results
                }).then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        console.log('is success');
                    } else {
                        console.log(response.details || 'Error');
                    }
                })
            } else if (currentTab > 1 && count > 1) {
                const apiUpdateRoute = route('api.formupdate', [theIndentifier.identifier, formpartial.id]);
                axios.put(apiUpdateRoute, {
                    content: results
                }).then((response) => {
                    if (response.status === 200) {
                        console.log('is updated');
                    } else {
                        console.log(response.details || 'Error');
                    }
                })
            }
        });

        $(prevBtn).on('click', (event) => {
            nextPrev(-1);
        })

        $(prevBtn).on('focus', function () {
            $(this).css('background-color', `${theme}`);
        })


        function incr() {
            var v1 = document.getElementById('p1').value;
            var statement = statementArray.reverse()[0];
            var statement2 = arrayElements2.reverse()[0];

            // if (statement === true || statement2 === false) {
            if (statement === true) {

                document.getElementById("p1").value = v1 + percentageCalc;
                let calC = document.getElementById("p1").value = v1 + percentageCalc;
                let passPercent = Math.round(calC);
                var percentSpan = document.getElementById('percentuale').innerText = `${passPercent}%`;
            } else if (statement === false) {

                document.getElementById("p1").value;
            }
        }

        function decr() {
            var v1 = document.getElementById('p1').value;

            document.getElementById("p1").value = v1 - percentageCalc;

            let calC = document.getElementById("p1").value = v1 - percentageCalc;
            let passPercent = Math.round(calC);
            var percentSpan = document.getElementById('percentuale').innerText = `${passPercent}%`;
        }

        $('#prevBtn').on('click', (event) => {
            decr();
        })

        $('#nextBtn').on('click', (event) => {
            incr();
        })


    })

})
